<template>
  <div/>
</template>

<script>
export default {
  name: 'LogoutView',
  created() {
    this.$store.commit('SET_LOGOUT_USER');
    this.$store.commit('SET_CLEAR_TOKEN');
    this.$router.push('/');
  },
};
</script>
